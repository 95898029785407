<template>
    <div :style="'background-image: url('+user.background_url+')'" style="background-size:cover" class="animate__animated animate__fadeIn fill-height">

        <loading-circle v-if="loading_profile"></loading-circle>

        <dialog-user ref="dialog_user"></dialog-user>

        <div v-if="!loading_profile">
            <v-card class="expansion-box" flat>

                <div v-if="$root.check_mod()"
                     class="profile-admin-options">
                    <v-icon color="red"
                            @click="$router.push($variables.v.navigation.profile_edit_id + $route.params.profileid)"
                            class="mr-3">
                        mdi-pencil-outline
                    </v-icon>

                    <v-icon color="red"
                            @click="$refs.dialog_user.open_dialog(user.userID)">
                        mdi-account
                    </v-icon>
                </div>


                <v-row>
                    <v-col class="col-12 col-sm-8 d-flex">
                        <div class="d-flex">
                            <div>
                                <v-avatar tile size="150">
                                    <img :src="c_valid_picture"
                                         @error="valid_picture = false"
                                         alt=""
                                         loading="lazy">
                                </v-avatar>
                            </div>
                            <div class="ml-5">
                                <div class="font-weight-bold d-flex" :class="$variables.v.roles.find(x => x.id === user.roleID).color">
                                    <div>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs"
                                                      v-on="on">
                                                    {{user.username}}
                                                </span>
                                            </template>
                                            <span>{{$t($variables.v.roles.find(x => x.id === user.roleID).name)}}</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="profile-flag-icon">
                                        <country-flag :country='user.country'/>
                                    </div>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs"
                                                    v-on="on">
                                                {{c_gender_icon}}
                                            </v-icon>
                                        </template>
                                        <span>{{$t(user.gender)}}</span>
                                    </v-tooltip>
                                    {{user.nickname}}
                                </div>
                                <div class="mt-5 content-from-textarea">{{user.description}}</div>
                            </div>
                        </div>
                    </v-col>

                    <v-col class="point-and-level-container col-12 col-sm-4">
                        <div>
                            <!--                        <div>{{$t('profile.level')}}: <span class="user-level">{{c_account_level}}</span></div>-->
                            <!--                    TODO: Ausgestelltes BADGE -->
                            <div>{{$t('profile.points')}} <span class="user-points">{{user.points}}</span></div>

                            <v-btn style="position:absolute;bottom:10px;"
                                   :color="$variables.v.theme.primary"
                                   depressed
                                   link
                                   :to="$variables.v.navigation.profile_edit" v-if="ownprofile">
                                {{$t('profile.edit-profile')}}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card>


            <!--            <v-row v-if="user.contact">-->
            <!--                <v-col class="col-12 col-md d-flex">-->
            <!--                </v-col>-->
            <!--            </v-row>-->


            <!--Last posts from this user-->
            <dialog-post ref="dialog_post" @voted="update_post"></dialog-post>

            <!--Desktop view -->
            <div v-if="$root.windowWidth >= 1264" class="expansion-box mt-10 d-flex">
                <v-col class="col-12 col-sm-8">
                    <span v-if="!loading_posts && posts.length > 0" class="font-weight-bold">{{$t('profile.last-posts1') + " " + posts.length + " " + $t('profile.last-posts2')}}</span>

                    <loading-circle v-if="loading_posts"></loading-circle>

                    <v-row v-if="!loading_posts">
                        <v-col :cols="$root.windowWidth < 1700 ? '6' : '4'" v-for="post in posts" :key="post.id">
                            <post :post="post"
                                  :hide_user="true"
                                  :show_points="true"
                                  @voted="update_post"
                                  @open_post="open_post">
                            </post>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="col-12 col-sm-4">
                    <profile-contactbox :contact_possibilities="user.contact"></profile-contactbox>


                    <!--                    <h3>{{$t('profile.badges')}}</h3>-->
                    <!--                    <br>-->
                    <!--                    COMMING SOON-->
                    <!--                    &lt;!&ndash;                    HIER BADGES AUSSTELLEN &ndash;&gt;-->
                </v-col>
            </div>


            <!--Mobile View-->
            <v-expansion-panels v-if="$root.windowWidth < 1264" class="mt-10">
                <v-expansion-panel class="expansion-box">
                    <v-expansion-panel-header>
                        <span v-if="!loading_posts && posts.length > 0">{{$t('profile.last-posts1')}} {{posts.length}} {{$t('profile.last-posts2')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <loading-circle v-if="loading_posts"></loading-circle>

                        <v-row v-if="!loading_posts">
                            <v-col :cols="$root.windowWidth > 700 ? '6' : '12'" v-for="post in posts" :key="post.id" class="px-0 px-sm-3">
                                <post :post="post"
                                      :hide_user="true"
                                      :show_points="true"
                                      @voted="update_post"
                                      @open_post="open_post">
                                </post>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <profile-contactbox v-if="$root.windowWidth < 1264" :contact_possibilities="user.contact"></profile-contactbox>
        </div>
    </div>
</template>

<script>
    import profile_contactbox from "../components/profile-contactbox";


    export default {
        components: {
            "profile-contactbox": profile_contactbox
        },
        data() {
            return {
                posts                 : [],
                confirmed_posts_amount: 0,
                user                  : {},
                ownprofile            : false,
                loading_profile       : false,
                loading_posts         : false,
                valid_picture         : true,
            }
        },
        watch     : {
            "$route.params.profileid"() {
                this.init();
            },
        },
        created() {
            this.init();
        },
        mounted() {
            setTimeout(async () => {
                    if (this.$route.params.postid) // Open Post if URL contains a Postid
                        await this.$refs.dialog_post.open_dialog(this.$route.params.postid);
                }, 1000
            );
        },
        methods   : {
            async init() {
                this.loading_profile = true;
                this.valid_picture   = true;
                if (this.$route.params.profileid) {
                    let response = await this.func.ax("/php/getter.php", {
                        func           : "get_profile",
                        user_id_or_name: this.$route.params.profileid,
                        userid         : this.$user.user.id,
                        token          : this.$user.user.token,
                    });
                    if (this.func.isObject(response)) {
                        this.ownprofile = this.$user.user.id === response.userID;
                        this.user       = JSON.parse(JSON.stringify(response));
                        this.get_last_posts();
                    }
                    else if (this.$variables.v.debug_show_php_errors || response.substr(0, 4) === "php.") {
                        this.eventbus.$emit("SB", response, "error");
                        await this.$router.push(this.$variables.v.navigation.default);
                    }
                    else
                        await this.$router.push(this.$variables.v.navigation.default);
                }
                this.loading_profile = false;
            },
            open_post(postid) {
                this.$refs.dialog_post.open_dialog(postid);
            },
            async get_last_posts() {
                this.loading_posts = true;

                this.posts = await this.func.ax("/php/getter.php", {
                    func       : "get_profile_posts",
                    userid     : this.$user.user.id,
                    posterid   : this.user.userID,
                    posts_limit: this.$variables.v.profile_max_last_posts, // Fill up the posts when one get deleted
                });

                this.loading_posts = false;
            },
            update_post(postid, action, points) {
                this.posts.find(x => x.ID === postid).action = action;
                this.posts.find(x => x.ID === postid).points = points;
            },
        },
        computed  : {
            c_valid_picture() {
                return this.valid_picture ? this.user.avatar_url : this.$variables.v.profile_default_avatar;
            },
            c_account_level() {
                return Math.floor(this.user.points / Math.pow(this.$variables.v.profile_level_requirement, this.$variables.v.profile_level_multiplicator));
            },
            c_gender_icon() {
                return this.$variables.v.genderlist.find(x => x.text === this.user.gender).icon;
            },
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-profile')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>