<template>
    <v-expansion-panels v-if="contact_possibilities.length > 0" class="mb-5">
        <v-expansion-panel class="expansion-box">
            <v-expansion-panel-header>
                <span class="font-weight-bold">{{$t('profile.contact')}}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-for="(contact, index) in contact_possibilities" :key="index" class="mb-2">
                    <div class="mb-2 font-weight-bold">
                        {{contact.description}}
                    </div>
                    <div class="mb-2">
                        {{contact.text}}
                    </div>
                    <v-divider v-if="index !== contact_possibilities.length-1"></v-divider>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    export default {
        props: ['contact_possibilities'],
    }
</script>